export const environment = {
  production: false,
  microservices: {
    marketplace: "https://api-marketplace-dev.encargomio.com/api/v1/marketplace/",
    user: "https://api-user-dev.encargomio.com/api/v1/user/",
    management: "https://api-management-dev.encargomio.com/api/v1/management/",
    orders: "https://api-orders-dev.encargomio.com/api/v1/orders/",
    scraping:
      "https://dev.api-fn.encargomio.com/web-scrapping-page-old",
    updateConveyor:
      "https://dev.api-fn.encargomio.com/update-status-conveyor",
    lambdaServientrega:
      "https://dev.api-fn.encargomio.com/create-servientrega-guide",
    lambdaCourierBox:
      "https://dev.api-fn.encargomio.com/create-guide-courierbox",
    lambdaFaster:
      "https://dev.api-fn.encargomio.com/create-guide-faster",
    lambdaFasterCity:
      "https://dev.api-fn.encargomio.com/get-city-faster",
    lambdaServientregaCredentials: {
      user: "TEST",
      password: "TEST2021",
      url: "http://40.121.157.134/solucionservientrega.com/ws/wsCargaGuias.asmx",
    },
    lambdaServientregaNational:
      "https://dev.api-fn.encargomio.com/create-servientrega-nac-guide",
    lambdaServientregaNationalCredentials: {
      user: "Luis1937",
      pass: "MZR0zNqnI/KplFlYXiFk7m8/G/Iqxb3O",
      facturation_code: "SER408",
      url: "http://190.131.194.159:8059/GeneracionGuias.asmx",
    },
    lambdaMasiveGuides:
      "https://dev.api-fn.encargomio.com/generate-masive-guides",
  },
  scraping: {
    nike: "https://gtvlog20r6.execute-api.us-east-1.amazonaws.com/dev/web-scraping-nike",
  },
  firebase: {
    apiKey: "AIzaSyAijFdANf7CwmeJPVuivw69bsrwSjQAYk8",
    authDomain: "encargomio-ffa52.firebaseapp.com",
    projectId: "encargomio-ffa52",
    storageBucket: "encargomio-ffa52.appspot.com",
    messagingSenderId: "257092213866",
    appId: "1:257092213866:web:dd9af48b99943bdf0cbe13",
    vapidKey:
      "BEZzVfb2YyvXhgOVt-cQ_neVGLfPy4qydQfTs0-RSkBjXnr-syKGq_NzKPHljn06jX046LvEXNFsFq5Ckgr6kNI",
  },
};
