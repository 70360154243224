import { MenuItem } from "./menu.model";

export const MENU: MenuItem[] = [
  {
    id: 1,
    label: "General",
    showItem: false,
    icon: "bx-store",
    subItems: [
      {
        id: 11,
        label: "Ordenes",
        link: "/ecommerce/orders",
        parentId: 1,
        code: "CO000",
        showItem: false,
      },
      {
        id: 12,
        label: "Envios",
        link: "/ecommerce/orders-shippings",
        parentId: 1,
        code: "ENV000",
        icon: 'bx-home'
      },
      {
        id: 2,
        label: 'Productos',
        showItem: false,
      },
      {
        id: 13,
        label: "Compras",
        link: "/purchases",
        parentId: 1,
        code: "COM000",
        showItem: false,
      },
      {
        id: 14,
        label: "Pagos",
        link: "/ecommerce/customers",
        parentId: 1,
        code: "PAG000",
        showItem: false,
      },
      {
        id: 15,
        label: "Cupones",
        link: "/cupons",
        parentId: 1,
        code: "CUP000",
        showItem: false,
      },
    ],
  },
  {
    id: 2,
    label: "Productos",
    showItem: false,
    icon: "bxl-dropbox",
    subItems: [
      {
        id: 21,
        label: "General",
        link: "/products/all",
        parentId: 2,
        code: "PR000",
        showItem: false,
      },
      {
        id: 22,
        label: "Producto",
        link: "/products/search",
        parentId: 2,
        code: "PR100",
        showItem: false,
      },
    ],
  },
  {
    id: 3,
    label: "Usuarios",
    icon: "bxs-user-detail",
    showItem: false,
    subItems: [
      {
        id: 31,
        label: "Roles y Permisos",
        link: "/contacts/grid",
        parentId: 3,
        code: "ROL000",
        showItem: false,
      },
      {
        id: 32,
        label: "Lista de Usuarios",
        link: "/contacts/list",
        parentId: 3,
        code: "US000",
        showItem: false,
      },
    ],
  },
  {
    id: 4,
    label: "Casilleros",
    icon: "bxs-box",
    showItem: false,
    subItems: [
      {
        id: 41,
        label: "Productos en Casillero",
        parentId: 4,
        link: "/lockers/locker",
        code: "CAS000",
        showItem: false,
      },
      {
        id: 42,
        label: "Productos de usuario",
        parentId: 4,
        link: "/lockers/user-products",
        code: "CAS100",
        showItem: false,
      },
      {
        id: 43,
        label: "Ingreso a Casillero",
        parentId: 4,
        link: "/lockers/insert-in-locker",
        code: "CAS100",
        showItem: false,
      },
      {
        id: 76,
        label: "Pre Alertas",
        parentId: 4,
        link: "/lockers/pre-alerts",
        code: "PA000",
        showItem: false,
      },      
    ],
  },
  {
    id: 4,
    label: "Tarifas",
    icon: "bxs-calculator",
    showItem: false,
    subItems: [
      {
        id: 41,
        label: "Nueva Tarifa",
        parentId: 4,
        link: "/formules/new-formule",
        code: "TAF000",
        showItem: false,
      },
      {
        id: 42,
        label: "Lista de tarifas",
        parentId: 4,
        link: "/formules/formules-list",
        code: "TAF100",
        showItem: false,
      },
    ],
  },
  {
    id: 5,
    label: 'Marketplace',
    icon: 'bx-store',
    showItem: false,
    subItems: [
      {
        id: 51,
        label: 'Importación Amazon',
        parentId: 5,
        link: '/marketplace/products',
        code: 'MAR001',
        showItem: false
      },
      {
        id: 52,
        label: 'Importación Webscrapping',
        parentId: 5,
        link: '/webscrapping/webscrapping-import',
        code: 'MAR004',
        showItem: false
      }
    ]
  },
  {
    id: 9,
    label: 'Consolidado CourierBox',
    icon: 'bx-package',
    showItem: true,
    link: '/courier-box'
  },
  {
    id: 10,
    label: 'Ingreso a casillero',
    icon: 'bx-box',
    link: "/lockers/insert-in-locker",
    showItem: true,
  },
  {
    id: 9,
    label: 'Configuraciones',
    icon: 'bx-cog',
    showItem: false,
    subItems: [
      {
        id: 51,
        label: 'Webscrapping',
        parentId: 9,
        link: '/webscrapping/webscrapping',
        code: 'MAR002',
        showItem: true
      },
      {
        id: 53,
        label: 'Información de envíos',
        parentId: 9,
        link: '/shippings/shipping-information-details',
        code: 'SID000',
        showItem: true
      },
      {
        id: 54,
        label: 'Envíos adicionales',
        parentId: 9,
        link: '/shippings/shipping-additionals',
        code: 'SA000',
        showItem: true
      },
      {
        id: 55,
        label: 'Tipos de envio',
        parentId: 9,
        link: '/shippings/shipping-types',
        code: 'ST000',
        showItem: true
      },

    ]
  },
  {
    id: 18,
    label: 'Contabilidad',
    icon: 'bxs-calculator',
    showItem: true,
    subItems: [
      {
        id: 71,
        label: "Tarjetas de credito",
        parentId: 18,
        link: "/credit-cards",
        code: "CRED001",
        showItem: false
      },
      {
        id: 72,
        label: "Tiendas",
        parentId: 18,
        link: "/stores",
        code: "STO000",
        showItem: true
      },
      {
        id: 73,
        label: "Extractos bancarios",
        parentId: 18,
        link: "/bank-statements",
        code: "BST000",
        showItem: true
      },
      {
        id: 77,
        label: "Categorías gastos administrativos",
        parentId: 18,
        link: "/admin-expense-categories",
        code: "CGA001",
        showItem: true
      },
      {
        id: 74,
        label: "Ver compras relacionadas",
        parentId: 18,
        link: "/bank-statements-show",
        code: "BST001",
        showItem: true
      },
      {
        id: 74,
        label: "Ver gastos administrativos",
        parentId: 18,
        link: "/admin-expenses",
        code: "CGA002",
        showItem: true
      }
    ]
  },
  {
    id: 19,
    label: 'Reportes',
    icon: 'bxs-flag',
    showItem: true,
    subItems: [
      {
        id: 75,
        label: "Trackings",
        parentId: 19,
        link: "/trackings",
        code: "RT000",
        showItem: false
      },
      {
        id: 76,
        label: "Envíos retrasados",
        parentId: 19,
        link: "/delay-shippings",
        code: "ENV001",
        showItem: false
      },
    ]
  }
  
];
